import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'

import Markdown from '@/components/atoms/Markdown'

export const markdownRenderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const contentType = node?.data?.target?.sys?.contentType?.sys?.id
      const content = node?.data?.target?.fields

      if (!content) {
        return null
      }

      if (contentType === 'moduleMarkdownText') {
        return (
          <div className="prose lg:max-w-[846px]">
            <Markdown>{content.markdownText}</Markdown>
          </div>
        )
      }
    },
  },
}
