import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { NextImage } from '@/components/Image/NextImage'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getAutocompleteData } from '@/lib/searchspring'
import { getColorName, isEmpty } from '@/lib/helper'
import { markdownRenderOptions } from '@/constants/markdownRenderOptions'
import styleConfig from '@/styles/style-config'
import { enableModuleHeroPreview } from '@/lib/contentful/module'
import { productListViewEvent, prepareViewListItems } from '@/lib/ga-events'
import { useRouter } from 'next/router';

const ProductPopUp = dynamic(
  () => import('@/components/Product/ProductPopUp').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const Icon = dynamic(() => import('@/components/Icon/Icon').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})

const CTA = dynamic(() => import('@/components/CTA').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})

const ModuleHero = (props) => {
  const router = useRouter();
  // let currentPath = router.asPath;
  // currentPath = currentPath.includes('?') ? currentPath.split('?')[0] : currentPath
  // // PROMOTION ID
  // const promotionId = (process.env.VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;

  // //PROMOTION NAME
  // let promotionName
  // if(currentPath == '/') {
  //   promotionName = 'Base | Static Page'
  // } else {
  //   let pageType = pagePaths.find(page => page.path == currentPath)
  //   promotionName = pageType?.type ? pageType?.type : 'Base | Static Page'
  // }
  // console.log(promotionName)
  const { preview } = props
  const [item, setItem] = useState(props)
  const [showProductMenu, setShowProductMenu] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [gaData, setGaData] = useState(null)

  const getType = (data) => {
    return data?.fields?.type || 'Normal'
  }

  const getAlignment = (data) => {
    return data?.fields?.alignment || 'Bottom Left'
  }

  const getOverlay = (data) => {
    return data?.fields?.overlay || 'Empty'
  }

  const getBackgroundImage = (data, screen = 'desktop') => {
    const backgroundImage = data?.fields?.backgroundImage

    if (screen == 'desktop') {
      return backgroundImage?.fields?.desktop?.fields?.file?.url || null
    }

    if (screen == 'tablet') {
      return (
        backgroundImage?.fields?.tablet?.fields?.file?.url ||
        backgroundImage?.fields?.desktop?.fields?.file?.url ||
        null
      )
    }

    if (screen == 'mobile') {
      return (
        backgroundImage?.fields?.mobile?.fields?.file?.url ||
        backgroundImage?.fields?.tablet?.fields?.file?.url ||
        backgroundImage?.fields?.desktop?.fields?.file?.url ||
        null
      )
    }
  }

  const getAltText = (data) => {
    const backgroundImage = data?.fields?.backgroundImage

    return (
      backgroundImage?.fields?.mobile?.fields?.description ||
      backgroundImage?.fields?.tablet?.fields?.description ||
      backgroundImage?.fields?.desktop?.fields?.description ||
      backgroundImage?.fields?.altText ||
      ''
    )
  }

  const getTextContentClass = (data) => {
    const alignmentText = getAlignment(data) == 'Center Center' ? 'Center' : getAlignment(data)
    return `hero-${alignmentText?.replace(' ', '-')?.toLowerCase()}-block`
  }

  const getTextColorClass = (data) => {
    return getOverlay(data) == 'Empty' || getOverlay(data) == 'Dark' ? 'text-white' : 'text-black'
  }

  const getTextPositionClass = (data) => {
    return getAlignment(data)?.includes('Left')
      ? 'text-left'
      : getAlignment(data)?.includes('Right')
      ? 'text-right'
      : 'text-center'
  }

  const getPositionClasses = (data) => {
    return getAlignment(data)?.includes('Left')
      ? 'justify-start items-start'
      : getAlignment(data)?.includes('Right')
      ? 'justify-end items-end'
      : 'justify-center items-center'
  }

  // convert new layout to old layout to use existing content
  const heroTypeMap = {
    'Center Left': 'Hero Center Left',
    'Center Center': 'Hero Center',
    'Center Right': 'Hero Center Right',
    'Bottom Left': 'Hero Bottom Left',
    'Bottom Center': 'Hero Bottom Center',
    'Bottom Right': 'Hero Bottom Right',
  }

  // cta style
  const buttonStyle = `
      .desktop-btn {
        padding:16px;
        line-height: 125%;
        margin-top: 10px;
        margin-bottom: 10px;
        min-width:159px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      @media (min-width: ${styleConfig.theme.screens.lg}) {
        .desktop-btn {
          width: 250px;
          height: 56px;
          padding: 20px 0;
          font-size: 14px;
          line-height: 125%;
        }
      }
    `

  const getProductData = async (data) => {
    const url = new URL(
      `https://${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/search/autocomplete.json?siteId=${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}&resultsFormat=native&redirectResponse=full`
    )
    let productSearchData = []

    await Promise.all(
      data?.fields?.products?.map(async (product) => {
        try {
          const response = await getAutocompleteData(url.href, product?.fields?.productId)
          let results = null

          if (response?.results) {
            results = response?.results
          }

          if (results && results.length > 0) {
            let variantCollection = JSON.parse(results[0].ss_variants.replace(/&quot;/g, '"'))
            let variantData = {}
            const foundItem = variantCollection.find((item) => {
              return item.sizes.some((size) => size.sku === product?.fields?.productId)
            })

            variantData = {
              productSlug: `/products/${results[0].url}/${getColorName(foundItem?.color)}`,
              productName: results[0]?.name,
              productImage: foundItem?.image,
              productPrice: foundItem?.price,
              productId: product?.fields?.productId,
            }
            productSearchData.push(variantData)
          }
        } catch (error) {
          console.error('search data error', error)
        }
      })
    )

    if (productSearchData?.length > 0) {
      let newGaProducts = await prepareViewListItems(productSearchData, 'Hero')

      if (newGaProducts?.length > 0) {
        productSearchData = productSearchData?.map((product) => {
          let skuPartials = product?.productId?.split('-')
          let gaProduct = newGaProducts?.find((product) => product?.sku == skuPartials?.[0])

          return {
            ...product,
            gaData: gaProduct || null,
          }
        })

        setGaData(newGaProducts)
      }
    }

    setProductsList(productSearchData)
  }

  const initializeData = async () => {
    try {
      let data = item

      if (preview) {
        const res = await enableModuleHeroPreview(props, preview)
        setItem(res)
        data = res
      }

      if (data?.fields?.products?.length > 0) {
        getProductData(data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    initializeData()
  }, [])

  useEffect(() => {
    if (showProductMenu && gaData) {
      productListViewEvent(gaData)
    }
  }, [showProductMenu])

  return (
    <>
      <div
        className={`module-hero ${item?.fields?.spaceBelowContent ? 'pb-15 md:pb-[100px]' : ''}`}
      >
        <div
          className={`module-hero-content relative bg-center bg-cover ${
            getType(item) == 'Full' ? 'full-height' : ''
          } ${getType(item) == 'Inset' ? 'mx-[24px] lg:mx-[80px]' : ''} ${
            getType(item) == 'Short' ? 'short-height' : ''
          }`}
        >
          {item?.fields?.video?.fields?.file?.url && (
            <div className="z-20 imageBackground">
              <video
                className={'video'}
                autoPlay={'autoplay'}
                muted={'muted'}
                loop={'loop'}
                playsInline
              >
                <source src={`${item?.fields?.video?.fields?.file?.url}`} type={'video/mp4'} />
              </video>
            </div>
          )}
          {/* Background image */}
          {getBackgroundImage(item) && !item?.fields?.video?.fields?.file?.url && (
            <div className="z-10 bg-[#fff] imageBackground desktop-image-background">
              <NextImage
                src={`https:${getBackgroundImage(item)}`}
                layout="fill"
                alt={getAltText(item)}
                quality={90}
                objectFit="cover"
                objectPosition="center"
                priority={item?.index == 0 ? true : false}
              />
            </div>
          )}
          {getBackgroundImage(item, 'tablet') && !item?.fields?.video?.fields?.file?.url && (
            <div className="z-10 bg-[#fff] imageBackground tablet-image-background">
              <NextImage
                src={`https:${getBackgroundImage(item, 'tablet')}`}
                layout="fill"
                alt={getAltText(item)}
                quality={90}
                objectFit="cover"
                objectPosition="center"
                priority={item?.index == 0 ? true : false}
              />
            </div>
          )}
          {getBackgroundImage(item, 'mobile') && !item?.fields?.video?.fields?.file?.url && (
            <div className="z-10 bg-[#fff] imageBackground mobile-image-background">
              <NextImage
                src={`https:${getBackgroundImage(item, 'mobile')}`}
                layout="fill"
                alt={getAltText(item)}
                quality={90}
                objectFit="cover"
                objectPosition="center"
                priority={item?.index == 0 ? true : false}
              />
            </div>
          )}
          {/* Text content */}
          <div
            className={`overflow-hidden flex absolute items-center text-center xs:max-w-full md:max-w-[600px] ${getTextContentClass(
              item
            )} ${
              getType(item) == 'Inset'
                ? 'max-w-none xs:max-w-[250px] md:max-w-[600px] max-w-unset'
                : ''
            } ${
              getType(item) == 'Short' && item?.fields?.ctas?.length > 0
                ? getAlignment(item)?.includes('Left')
                  ? 'pr-[35px] md:pr-0'
                  : getAlignment(item)?.includes('Right')
                  ? 'pl-[35px] md:pl-0'
                  : 'px-[35px] md:px-0'
                : ''
            }`}
          >
            <div
              className={`relative ${
                getType(item) == 'Short' ? 'px-[20px] pb-[20px]' : 'px-6'
              } ${getTextColorClass(item)}`}
            >
              {item?.fields?.eyebrow && (
                <p
                  className={`font-bold leading-tight text-xs md:text-sm uppercase ${getTextPositionClass(
                    item
                  )}`}
                >
                  {item?.fields?.eyebrow}
                </p>
              )}
              {item?.fields?.headline && (
                <h1
                  className={`uppercase font-bold text-[32px] md:text-[50px] leading-[38px] md:leading-[50px] ${getTextPositionClass(
                    item
                  )} ${item?.fields?.spaceBelowTextContent ? 'mt-[25px]' : 'my-3'}`}
                >
                  {item?.fields?.headline}
                </h1>
              )}
              {!isEmpty(
                documentToReactComponents(item?.fields?.bodyText, markdownRenderOptions)
              ) && (
                <div
                  className={`body-text ${
                    getType(item) == 'Short'
                      ? 'leading-6 text-sm pt-2'
                      : 'text-sm md:text-md lg:mt-[18px] leading-[26px]'
                  } promo-title-sub ${getTextPositionClass(item)}`}
                >
                  {documentToReactComponents(item?.fields?.bodyText, markdownRenderOptions)}
                </div>
              )}
              {item?.fields?.ctas?.length > 0 && (
                <div
                  className={`button-group-wrap flex flex-col lg:flex-row ${
                    getType(item) == 'Short' ? 'mt-[10px] space-y-[20px] lg:space-y-0' : 'mt-[30px]'
                  } ${getPositionClasses(item)}`}
                >
                  {item?.fields?.ctas?.map((cta, index) => {
                    if (cta?.fields?.url && cta?.fields?.label) {
                      return (
                        <CTA
                          className={`btn btn-white leading-normal tracking-widest md:px-[24px] desktop-btn ${
                            item?.fields?.ctas?.length == 2 && index == 0 ? 'lg:mr-5' : ''
                          }`}
                          label={cta?.fields?.label}
                          inlineStyles={buttonStyle}
                          openInNewTab={cta?.fields?.openInNewTab}
                          openVideoPlayer={cta?.fields?.openVideoPlayer}
                          url={cta?.fields?.url}
                          background={cta?.fields?.backgroundColor}
                          moduleProps={props}
                        />
                      )
                    }
                  })}
                </div>
              )}
            </div>
          </div>
          {/* Products popup */}
          {item?.fields?.products?.length > 0 && (
            <div className={`absolute left-0 text-left w-[100%] bottom-[80px]`}>
              <div className="relative z-10">
                <div
                  className={`absolute bottom-55 bottom-30 p-1 rounded-full circle-section cursor-pointer ${
                    getAlignment(item) === 'Bottom Right' || getAlignment(item) === 'Center Right'
                      ? 'md:left-20 left-4'
                      : 'md:right-20 right-4'
                  }`}
                  onClick={(e) => {
                    setShowProductMenu(true)
                  }}
                  onMouseEnter={() => {
                    setShowProductMenu(true)
                  }}
                >
                  <div
                    className={`${
                      showProductMenu !== null
                        ? showProductMenu
                          ? ''
                          : 'plus-icon-on-show'
                        : 'plus-icon-on-reload'
                    }`}
                  >
                    <Icon
                      className={`cursor-pointer text-white fill-current w-7 h-7`}
                      viewBox={`0 0 36 36`}
                      size={36}
                      icon="plus-circle"
                    />
                  </div>
                  {/* {showProductMenu ? ( */}
                    <ProductPopUp
                      showProductMenu={showProductMenu}
                      setShowProductMenu={setShowProductMenu}
                      products={productsList}
                      heroType={heroTypeMap[getAlignment(item)]}
                      moduleProps={props}
                    />
                  {/* ) : null} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .imageBackground {
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
        }
        .imageBackground::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: ${getOverlay(item) === 'Dark'
            ? `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.2) 39.06%, rgba(0, 0, 0, 0) 68.23%, rgba(0, 0, 0, 0) 100%),
          linear-gradient(359.88deg, rgba(0, 0, 0, 0.5) 0.35%, rgba(0, 0, 0, 0.2) 32.33%, rgba(0, 0, 0, 0) 78.07%),
          linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) );`
            : getOverlay(item) === 'Light'
            ? `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))`
            : getOverlay(item) === 'Empty' && 'none'};
        }

        .video {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
        }
      `}</style>
    </>
  )
}

export default ModuleHero
